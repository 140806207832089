export default () => ({
    show: false,

    init() {
        window.addEventListener(
            'app:open-modal',
            event => {
                if (event.detail?.id === this.$root.id) {
                    this.open(event.detail?.trigger);
                }
            },
            false
        );

        this.$root.addEventListener('close', () => {
            window.modalCount--;
            if (!window.modalCount) {
                document.body.classList.remove('overflow-hidden');
            }
            this.show = false;
            this.$dispatch('app:close-modal', { id: this.$root.id });
        });

        if (this.$root.dataset.noClose) {
            this.$root.addEventListener('cancel', event => event.preventDefault());
        }
    },

    open(trigger) {
        this.$root.showModal();
        this.show = true;
        window.modalCount = window.modalCount ? window.modalCount + 1 : 1;
        document.body.classList.add('overflow-hidden');
        this.$root.style.maxHeight = `${window.innerHeight - 64}px`;
        this.$dispatch('app:modal-opened', { id: this.$root.id, trigger: trigger });
    },

    close() {
        this.$root.setAttribute('closing', true);
        this.$root.addEventListener(
            'animationend',
            () => {
                this.$root.close();
                this.$root.removeAttribute('closing');
            },
            { once: true }
        );
    },

    closeInstant() {
        this.$root.close();
    },
});
