export default () => ({
    visible: false,
    hidden: true,
    toggleResponsive: { sm: false, md: false, lg: false },

    init() {
        if (this.$root.dataset?.target?.length) {
            const first = document.querySelector(this.$root.dataset.target);
            if (first?.classList?.contains('sm:hidden')) {
                this.toggleResponsive.sm = true;
            }
            if (first?.classList?.contains('md:hidden')) {
                this.toggleResponsive.md = true;
            }
            if (first?.classList?.contains('lg:hidden')) {
                this.toggleResponsive.lg = true;
            }
        }

        if (this.$root.dataset?.visible) {
            this.visible = true;
            this.hidden = false;
        }
    },

    toggle() {
        this.visible = !this.visible;
        this.hidden = !this.hidden;
        if (this.$root.dataset?.target?.length) {
            document.querySelectorAll(this.$root.dataset.target).forEach(elem => {
                elem.classList.toggle('hidden');
                if (this.toggleResponsive.sm) {
                    elem.classList.toggle('sm:hidden');
                }
                if (this.toggleResponsive.md) {
                    elem.classList.toggle('md:hidden');
                }
                if (this.toggleResponsive.lg) {
                    elem.classList.toggle('lg:hidden');
                }
            });
        }
    },

    show() {
        this.visible = true;
        this.hidden = false;
    },

    hide() {
        this.visible = false;
        this.hidden = true;
    },

    hiddenClass() {
        return this.hidden ? 'hidden' : '';
    },
});
