export default () => ({
    rows: null,
    show: false,
    showOnlyAvailable: false,
    min: 10,
    button: null,
    closedHtml: 'Alle <span></span> Termine anzeigen',
    openedHtml: 'Weniger Termine anzeigen',

    init() {
        this.rows = this.$root.querySelectorAll('tbody tr:not(.price-scale-level)');

        if (this.rows.length > this.min && !this.$root.hasAttribute('data-ps')) {
            this.button = document.createElement('button');
            this.button.classList.add(
                'text-lg-xl',
                'font-semibold',
                'border-b',
                'border-gray-900',
                'hover:border-transparent',
                'p-1',
                'mx-auto',
                'block'
            );
            this.button.innerHTML = this.closedHtml;
            this.button.addEventListener('click', event => {
                event.preventDefault();
                this.show = !this.show;
                this.toggleRows();
                this.button.innerHTML = this.show ? this.openedHtml : this.closedHtml;
                if (!this.show) {
                    this.$dispatch('app:scroll-to', { selector: '.trip-dates' });
                }
            });

            this.$root.after(this.button);
            this.toggleRows();
        }
    },

    toggleRows() {
        let count = 0;
        this.rows.forEach(row => {
            let visible = !row.classList.contains('not-shown');
            if (this.showOnlyAvailable && row.classList.contains('not-available')) {
                visible = false;
            }
            if (visible) {
                row.classList.remove('!hidden');
                if (count > this.min) {
                    if (this.show) {
                        row.classList.remove('!hidden');
                    } else {
                        row.classList.add('!hidden');
                    }
                }
                count++;
            } else {
                row.classList.add('!hidden');
            }
            if (!this.show) {
                this.button.innerHTML = this.closedHtml;
                this.button.querySelector('span').innerHTML = count;
            }
        });
    },

    toggleYear(event) {
        for (let element of Array.from(this.$root.querySelectorAll(`tbody tr.${event.target.value}`))) {
            element.classList.toggle('not-shown');
        }
        this.toggleRows();
    },

    toggleAvailable(event) {
        this.showOnlyAvailable = !this.showOnlyAvailable;
        this.toggleRows();
    },

    loadTripItineraryForDate(event) {
        location.hash = '#reiseverlauf';
        window.Livewire.emit('trip-itinerary:load-date', this.$el.dataset.date);
    },
});
