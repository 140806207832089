import { debounce, throttle } from 'lodash';

function setHeightUtilities() {
    window.headerHeight = document.getElementById('header')?.offsetHeight || 0;
    window.navHeight = window.headerHeight + (document.getElementById('secondary-nav')?.offsetHeight || 0);
    window.scrollOffset = window.navHeight;
}

setHeightUtilities();

window.addEventListener('resize', debounce(setHeightUtilities, 200));
window.addEventListener('scroll', throttle(setHeightUtilities, 100), { passive: true });
window.addEventListener('load', setHeightUtilities);

window.isMinScreenSize = size => {
    const map = {
        xs: '(min-width: 480px)',
        sm: '(min-width: 640px)',
        md: '(min-width: 768px)',
        lg: '(min-width: 1024px)',
        xl: '(min-width: 1280px)',
        '2xl': '(min-width: 1536px)',
    };

    return window.matchMedia(map[size]).matches;
};

window.isTouchDevice = () => 'ontouchstart' in window;
