export default () => ({
    shareFacebook() {
        this.share('facebook');
    },

    shareX() {
        this.share('x');
    },

    shareWhatsapp() {
        this.share('whatsapp');
    },

    sharePinterest() {
        this.share('pinterest');
    },

    copy(event) {
        const button = event.target.closest('button');
        navigator.clipboard.writeText(this.url(false)).then(() => {
            button?.classList.add('text-success');
            button?.classList.remove('hover:text-primary-500');
            button?.querySelector('.text').classList.add('hidden');
            button?.querySelector('.text-copied').classList.remove('hidden');
        });
    },

    share(type) {
        let shareUrl;
        if (type === 'facebook') {
            shareUrl = `//www.facebook.com/sharer.php?&p[url]=${this.url()}`;
        }
        if (type === 'x') {
            shareUrl = `//twitter.com/intent/tweet?via=DIAMIR&url=${this.url()}&text=${this.name()}`;
        }
        if (type === 'whatsapp') {
            shareUrl = `whatsapp://send?text=https%3A%2F%2F${this.url()}`;
        }
        if (type === 'pinterest') {
            shareUrl = `//pinterest.com/pin/create/bookmarklet?url=${this.url()}&amp;description=${this.description()}&amp;media=${this.image()}`;
        }
        this.$dispatch('app:tag-manager-event', { event: 'share', params: { method: type, content_type: this.url() } });
        window.open(shareUrl, 'share', 'status=no,height=700,toolbar=0,resizable=1,width=600,left=50,top=50');
    },

    url(encoded = true) {
        const url = document.querySelector('meta[property="og:url"]').getAttribute('content');
        return encoded ? encodeURIComponent(url) : url;
    },

    name() {
        return encodeURIComponent(document.querySelector('meta[property="og:title"]').getAttribute('content'));
    },

    description() {
        return encodeURIComponent(document.querySelector('meta[property="og:description"]').getAttribute('content'));
    },

    image() {
        return encodeURIComponent(
            document.querySelector('meta[property="og:image:secure_url"]').getAttribute('content')
        );
    },
});
