function RunCallbackOnExistingConsentFor(callback, forPurpose = null, forVendor = null) {
    if (!window.dataLayer) {
        callback();
        return;
    }

    const status = __cmp('getCMPData');

    if (
        (status.consentExists === true && forPurpose === null && forVendor === null) ||
        (status.consentExists === true && status.vendorConsents[forVendor] === true) ||
        (status.consentExists === true &&
            status.purposeConsents[forPurpose] === true &&
            (forVendor === null || status.vendorConsents[forVendor] === true))
    ) {
        callback();
    }
}

window.cmpOnExistingConsentFor = (callback, forPurpose = null, forVendor = null) => {
    if (!window.dataLayer) {
        callback();
        return;
    }

    if (typeof __cmp !== 'function') {
        setTimeout(() => window.cmpOnExistingConsentFor(callback, forPurpose, forVendor), 100);
        return;
    }

    __cmp(
        'addEventListener',
        [
            'consent',
            (eventname, cmpobject, subtype) => RunCallbackOnExistingConsentFor(callback, forPurpose, forVendor),
            false,
        ],
        null
    );
};

window.cmpWhenConsentIsGivenForVendor = (callback, forVendor) => {
    if (!window.dataLayer) {
        callback();
        return;
    }

    if (typeof __cmp !== 'function') {
        setTimeout(() => window.cmpWhenConsentIsGivenForVendor(callback, forVendor), 100);
        return;
    }

    __cmp(
        'addEventListener',
        [
            'vendorconsent',
            (eventname, cmpobject, subtype) => {
                if (subtype === forVendor) {
                    callback();
                }
            },
            false,
        ],
        null
    );
};

window.cmpGoogleMapsPurpose = 'c8';
window.cmpGoogleMapsVendor = 's1104';

window.cmpGoogleMapsElement = element => {
    element.classList.add('cmplazyload');
    element.dataset.cmpPreviewBtn = 'Karte laden';
    element.dataset.cmpPurpose = window.cmpGoogleMapsPurpose;
    element.dataset.cmpVendor = window.cmpGoogleMapsVendor;
    element.dataset.cmpPreviewImage = 'https://www.diamir.de/image/cmp-maps.jpg/500';
};
