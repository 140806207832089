window.addEventListener(
    'app:update-title',
    event => {
        event.stopPropagation();

        if (event.detail?.title) {
            document.title = document.title.replace(/^.+\|/g, event.detail?.title + ' |');
        }
    },
    false
);

window.addEventListener(
    'app:scroll-to',
    event => {
        event.stopPropagation();

        const target = document.querySelector(event?.detail?.selector);

        const top = target?.getBoundingClientRect().top + window.scrollY - window.scrollOffset;

        window.scrollTo({
            top: top,
            left: 0,
            behavior: 'smooth',
        });

        if (target?.getAttribute('x-data') === 'toggle') {
            target?.querySelector('h4 > a[href="#"]')?.click();
        }
    },
    false
);

window.addEventListener(
    'app:tag-manager-event',
    event => {
        event.stopPropagation();

        if (event.detail?.event && event.detail?.params) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'ga_event',
                ga_event: { event: event.detail.event, ...event.detail.params },
            });
        }
    },
    false
);

window.addEventListener(
    'app:tag-manager-remarketing',
    event => {
        event.stopPropagation();

        if (event.detail?.length) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                remarketing: { ...event.detail.params },
            });
        }
    },
    false
);
