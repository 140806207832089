export default () => ({
    inputs: null,

    init() {
        this.inputs = Array.from(this.$el.querySelectorAll('input[type=checkbox]'));

        this.inputs.forEach(input => input.addEventListener('change', event => this.handleChangeEvent(event)));
    },

    handleChangeEvent(event) {
        if (event.target.checked) {
            this.inputs.forEach(input => {
                if (!input.isEqualNode(event.target)) {
                    input.checked = false;
                }
            });
        }
    },
});
