export default () => ({
    touchstartX: 0,
    touchendX: 0,
    touchstartY: 0,
    touchendY: 0,
    pagination: null,

    init() {
        this.pagination = this.$root.parentElement.querySelector('[x-data="pagination"]');

        this.$root.addEventListener(
            'touchstart',
            e => {
                this.touchstartX = e.changedTouches[0].screenX;
                this.touchstartY = e.changedTouches[0].screenY;
            },
            { passive: true }
        );

        this.$root.addEventListener(
            'touchend',
            e => {
                this.touchendX = e.changedTouches[0].screenX;
                this.touchendY = e.changedTouches[0].screenY;
                this.goToPage();
            },
            { passive: true }
        );
    },

    goToPage() {
        if (Math.abs(this.touchendX - this.touchstartX) < Math.abs(this.touchendY - this.touchstartY)) {
            return;
        }

        if (Math.abs(this.touchendX - this.touchstartX) < 0.33 * document.body.clientWidth) {
            return;
        }

        const next = this.pagination.querySelector('button[rel="next"]');
        const prev = this.pagination.querySelector('button[rel="prev"]');

        if (next && this.touchendX < this.touchstartX) {
            next.click();
        }

        if (prev && this.touchendX > this.touchstartX) {
            prev.click();
        }
    },
});
