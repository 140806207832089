export default () => ({
    init() {
        this.$el.addEventListener('click', event => {
            event.preventDefault();
            this.$dispatch('app:open-modal', { id: 'tour-guide-modal' });
            window.Livewire.emit(
                'tour-guide-modal:load',
                event.target.closest('a').dataset.tourGuideId,
                event.target.closest('a').dataset.dateId
            );
        });

        window.addEventListener(
            'app:close-modal',
            event => {
                if (event.detail?.id === 'tour-guide-modal') {
                    window.Livewire.emit('tour-guide-modal:close');
                }
            },
            false
        );
    },
});
