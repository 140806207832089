export default () => ({
    init() {
        if (this.$root.nodeName === 'SELECT') {
            this.$root.addEventListener('change', event => {
                const link = this.$root.value;
                if (link.length) {
                    this.goToLink(link);
                }
            });
        } else {
            this.$root.classList.add('hover:cursor-pointer');
            const link = this.$root.dataset.href;
            if (link.length) {
                this.$root.addEventListener('click', () => this.goToLink(link));
            }
        }
    },

    goToLink(link) {
        if (link.startsWith('http')) {
            window.open(link, '_blank').focus();
        } else {
            window.location.href = link;
        }
    },
});
