import tippy from 'tippy.js';

export default () => ({
    image: null,
    instance: null,
    init() {
        this.image = this.$root.nodeName === 'IMG' ? this.$root : this.$root.querySelector('img');
        let content = this.image?.getAttribute('alt');
        if (this.image && content !== '') {
            if (this.image.dataset.author !== '') {
                content += `<span class="inline-block ml-2 text-xs text-gray-300">${this.image.dataset.author}</span>`;
            }

            this.instance = tippy(this.$root, {
                content: content,
                allowHTML: true,
                arrow: false,
                theme: 'caption',
                placement: 'bottom',
                popperOptions: {
                    modifiers: [
                        {
                            name: 'sameWidth',
                            enabled: true,
                            phase: 'beforeWrite',
                            requires: ['computeStyles'],
                            fn({ state }) {
                                state.styles.popper.width = `${Math.round(state.rects.reference.width * 0.95)}px`;
                            },
                            effect({ state }) {
                                state.elements.popper.style.width = `${Math.round(
                                    state.elements.reference.offsetWidth * 0.95
                                )}px`;
                            },
                        },
                    ],
                },
            });
        }
    },
});
