export default () => ({
    feature: null,
    init() {
        this.feature = this.$root.dataset.feature;
        if (this.feature === 'bestseller-badge') {
            this.trackBestsellerBadgeClicks();
        }
    },
    trackBestsellerBadgeClicks() {
        this.$root.closest('.product-teaser').addEventListener('click', () => {
            this.$dispatch('app:tag-manager-event', {
                event: 'bestseller_click',
                params: { badge: !this.$root.classList.contains('hidden') },
            });
        });
    },
});
