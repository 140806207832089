export default () => ({
    init() {
        this.$el.addEventListener('click', event => {
            event.preventDefault();
            this.$dispatch('app:open-modal', {
                id: this.$el.dataset.modalId || this.$el.href?.replace('#'),
                trigger: this.$el,
            });
        });
    },
});
