export default () => ({
    init() {
        this.$root.style.display = 'none';
        this.$root.addEventListener('click', event => this.$dispatch('app:scroll-to', { selector: 'body' }));

        window.addEventListener(
            'scroll',
            event => {
                this.$root.style.display =
                    document.body.scrollTop > 40 || document.documentElement.scrollTop > 40 ? 'block' : 'none';
            },
            { passive: true }
        );
    },
});
