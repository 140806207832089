export default () => ({
    init() {
        this.$root.addEventListener('click', event => {
            event.preventDefault();
            const elem = event.target.closest('[data-employee-id]');
            this.$dispatch('app:open-modal', { id: elem.dataset.modalId });
            window.Livewire.emit(
                `${elem.dataset.modalId}:load`,
                elem.dataset.employeeId,
                elem.dataset.code || null,
                Boolean(Number(elem.dataset.allowAppointment) || true)
            );
        });

        window.addEventListener(
            'app:close-modal',
            event => {
                if (event.detail?.id === 'employee-contact-form-modal') {
                    window.Livewire.emit('employee-contact-form-modal:close');
                }
                if (event.detail?.id === 'employee-modal') {
                    window.Livewire.emit('employee-modal:close');
                }
            },
            false
        );
    },
});
