export default () => ({
    scrolledToFirstError: false,

    init() {
        this.update();
        Livewire.on('form:update', () => this.update());

        if (this.$el.querySelectorAll('[required], [pattern]').length) {
            this.$el.addEventListener(
                'invalid',
                event => {
                    if (!this.scrolledToFirstError) {
                        this.$dispatch('app:scroll-to', {
                            selector: event.target.id
                                ? `#${event.target.id}`
                                : `${event.target.tagName.toLowerCase()}${event.target.classList.length ? `.${[...event.target.classList].join('.')}` : ''}`,
                        });
                        this.scrolledToFirstError = true;
                    }
                    this.checkValidity(event.target);
                },
                true
            );
        }
    },

    update() {
        this.$el
            .querySelectorAll('[required], [pattern]')
            .forEach(elem => elem.addEventListener('blur', event => this.checkValidity(event.target)));
    },

    checkValidity(element) {
        const parent = element.parentNode;
        element.classList.remove('!border-danger', 'placeholder:!text-danger');
        parent.querySelector('.feedback')?.remove();
        if (!element.validity.valid) {
            element.classList.add('!border-danger', 'placeholder:!text-danger');
            const feedback = document.createElement('div');
            feedback.classList.add('feedback', 'text-xs', 'text-danger');
            feedback.textContent = element.validity.patternMismatch
                ? 'Das eingegebene Format ist ungültig.'
                : 'Dieses Feld ist erforderlich.';
            if (element.type === 'checkbox' || element.type === 'radio') {
                parent.append(feedback);
            } else {
                element.insertAdjacentElement('afterend', feedback);
            }
        }
    },
});
