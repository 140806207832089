export default () => ({
    init() {
        if (window.isMinScreenSize('sm')) {
            return;
        }

        const height = this.$root.clientHeight;

        if (height > 500) {
            this.$root.classList.add('overflow-hidden', 'h-[30rem]', 'relative');

            const div1 = document.createElement('div');
            div1.classList.add(
                'absolute',
                'bottom-8',
                'left-0',
                'h-16',
                'w-full',
                'z-10',
                'bg-gradient-to-t',
                'from-white',
                'to-transparent',
                'flex',
                'justify-center',
                'items-end'
            );

            this.$root.appendChild(div1);

            const div2 = document.createElement('div');
            div2.classList.add(
                'absolute',
                'bottom-0',
                'left-0',
                'h-8',
                'w-full',
                'z-10',
                'bg-white',
                'flex',
                'justify-center',
                'items-end'
            );

            const button = document.createElement('button');
            button.classList.add('text-teal-500', 'underline', 'hover:no-underline');
            button.innerHTML = 'Mehr anzeigen';

            button.addEventListener('click', event => {
                this.$root.classList.remove('overflow-hidden', 'h-[30rem]');
                div1.classList.add('hidden');
                div2.classList.add('hidden');
                button.classList.add('hidden');
            });

            div2.appendChild(button);
            this.$root.appendChild(div2);
        }
    },
});
