export default () => ({
    paginator: 'page',

    init() {
        this.paginator = this.$root.dataset.paginator || this.paginator;
    },

    set(event) {
        event.preventDefault();
        this.$wire.set(event.target.dataset.setProperty, event.target.dataset.setValue);
    },

    nextPage() {
        this.$wire.call('nextPage', this.paginator);
    },

    previousPage() {
        this.$wire.call('previousPage', this.paginator);
    },

    gotoPage(event) {
        const button = event.target.closest('button');
        this.$wire.call('gotoPage', button.dataset.page || button.innerHTML, this.paginator);
    },
});
