export default () => ({
    show: false,

    init() {
        window.Livewire.on('notifyErrors', () => setTimeout(() => (this.show = true), 200));
    },

    close() {
        this.show = false;
    },
});
