export default () => ({
    init() {
        if (this.$root.nodeName === 'A' && this.$root.getAttribute('href')?.startsWith('#')) {
            this.$root.addEventListener('click', event => {
                event.preventDefault();
                this.$dispatch('app:scroll-to', {
                    selector: this.$el.dataset.selector || this.$root.getAttribute('href'),
                });
                history.pushState({}, '', this.$root.getAttribute('href'));
            });
        } else {
            this.$dispatch('app:scroll-to', { selector: this.$el.dataset.selector });
        }
    },
});
