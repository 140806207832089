export default () => ({
    init() {
        this.$el.addEventListener('click', event => {
            event.preventDefault();
            this.$dispatch('app:open-modal', { id: 'accommodation-modal' });
            window.Livewire.emit(
                'accommodation-modal:load',
                event.target.closest('a').dataset.accommodationId,
                event.target.closest('a').dataset.accommodationType
            );
        });

        window.addEventListener(
            'app:close-modal',
            event => {
                if (event.detail?.id === 'accommodation-modal') {
                    window.Livewire.emit('accommodation-modal:close');
                }
            },
            false
        );
    },
});
