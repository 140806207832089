export default () => ({
    content: null,
    init() {
        this.content = this.$root.dataset?.content;
    },
    copy() {
        navigator.clipboard.writeText(this.content).then(() => {
            this.$root.querySelector('.content').classList.add('hidden');
            this.$root.querySelector('.content-copied').classList.remove('hidden');
        });
    },
});
