export default () => ({
    init() {
        this.set();
        Livewire.on('form:update', () => this.set());
    },

    set() {
        this.$root.classList.remove('not-empty');

        if (this.$root.value.length) {
            this.$root.classList.add('not-empty');
        }

        this.$root.addEventListener('blur', event => {
            if (event.target.value.length) {
                event.target.classList.add('not-empty');
            }
        });
    },
});
