export default () => ({
    input: null,
    span: null,
    init() {
        this.create();
        Livewire.on('form:update', () => this.create());
    },
    create() {
        this.$root.classList.add('relative');

        this.input = this.$root.querySelector('input');
        this.input.classList.add('absolute', 'w-full', 'left-0');

        this.span = document.createElement('span');
        this.span.textContent = this.input.value;
        this.span.classList.add('px-1', 'invisible');
        this.$root.prepend(this.span);

        this.input.addEventListener('keyup', () => {
            this.span.innerHTML = this.input.value;
        });
    },
});
