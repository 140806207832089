export default () => ({
    show: null,
    count: null,
    current: 0,

    init () {
        this.show = Number(this.$root.dataset.show);
        this.count = this.$root.querySelectorAll('.grid .tile').length;

        if (this.show > 0 && this.count > this.show) {
            this.showMore();
            this.$root.querySelector('.load-more').classList.toggle('hidden');
        }
    },

    showMore () {
        this.current += this.show;
        this.$root.querySelectorAll('.grid .tile').forEach((element, index) => {
            if (index < this.current) {
                element.classList.remove('hidden');
            } else {
                element.classList.add('hidden');
            }
        });
        if (this.count <= this.current) {
            this.$root.querySelector('.load-more').classList.toggle('hidden');
        }
    },
});
