export default () => ({
    show: false,
    timeout: null,

    init() {
        window.Livewire.on('notify', (message, errors = []) => {
            clearTimeout(this.timeout);
            this.show = true;
            if (!errors.length) {
                this.timeout = setTimeout(() => (this.show = false), 5000);
            }
        });
    },

    close() {
        this.show = false;
        clearTimeout(this.timeout);
    },
});
