export default () => ({
    autoClose: false,

    init() {
        if (this.autoClose) {
            setTimeout(() => this.$root.remove, 5000);
        }
    },

    close() {
        this.$root.remove();
    },
});
